/* fullscrean.css */

.image-container {
  /* Add your regular styles here */
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(
    0,
    0,
    0,
    0.9
  ); /* You can adjust the background color and opacity */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen img {
  max-width: 100%;
  max-height: 100%;
}

.exit-fullscreen-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.next-image-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.nav-button {
  position: fixed;
  bottom: 20px;
  background: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}



.thumbnail-bar {
  /* position: fixed; */
  /* bottom: 0; */
  /* left: 0; */
  width: 100%;
  display: flex;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.5); */
}

.thumbnail {
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.thumbnail.active {
  opacity: 1;
}

/* Add this style to your fullscrean.css file */
.thumbnail-bar.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px; /* Adjust the padding as needed */
  box-sizing: border-box;
}

.thumbnail-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  /* Add any other necessary styles here */
}
